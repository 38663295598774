@use 'sass:math';
@import '../global/style-guide.scss';

// Related videos
#relatedVideosSection,
#relatedVideosSectionInModal {
	margin: 0;

	.anchorLink {
		text-decoration: none;

		&:hover {
			color: var(--baseTextColor);
		}
	}

	.glider-track {
		padding: 2px 0;
	}

	.related-videos-wrapper {
		--gliderArrowTop: 50%;
		position: relative;
		z-index: 1;
	}

	.glider-slide {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin: 0 5px;
		min-width: 0;
		text-align: center;
	}

	.related-videos {
		.glider-container {
			margin: 0;

			&.glider-loading {	
				align-items: flex-start;
				display: flex;
				justify-content: flex-start;
				overflow: hidden;

				.professionalsLandingContainer & {
					justify-content: center;
				}

				.related-videos__item {
					margin: 0 5px;
					min-width: 274px;
					width: 274px;

					@media (max-width: 768px) {
						min-width: 192px;
						width: 192px;
					}
				}
			}
		}

		&-pros {
			.glider-track {
				margin: 0 auto;
			}
		}

		&__item {
			cursor: pointer;
			width: 100%;

			.video-thumbnail {
				justify-content: center;
				position: relative;
				width: 100%;
				z-index: 1;

				img {
					height: auto;
					margin: 0 auto;
					width: 100%;
				}

				&__play {
					$play_icon_pos: math.div(60, 2);
					align-items: center;
					align-self: center;
					left: calc((50%) - (#{$play_icon_pos}px));
					top: calc((50%) - (#{$play_icon_pos}px));
				}
			}

			&--inside-modal {
				&.related-videos__item .video-info {
					width: auto;
				}
			}

			.video-info {
				font-size: 1.4rem;
				margin: 0 auto;
				padding-top: 10px;
				max-width: 100%;
				width: 100%;

				&__headline {
					font-family: var(--fontHeading);
					margin: 0;
				}

				&__title {
					font-family: var(--fontHeading);
					margin: 0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	.playButton {
		align-items: center;
		align-self: center;
		border-radius: 50%;
		color: #fff;
		display: flex;
		font-size: 25px;
		height: 65px;
		justify-content: center;
		left: calc((50%) - (33px));
		position: absolute;
		top: calc((50%) - (40px));
		width: 65px;
		z-index: 10;
	}
}

#relatedVideosSectionInModal {
	margin-top: 30px;

	.glider-arrow {
		background: none;
		top: var(--gliderArrowTop);

		&.glider-prev {
			left: 0;

			&::before {
				left: 11px;
			}
		}

		&.glider-next {
			right: 0;

			&::before {
				right: 11px;
			}
		}

		&.glider-prev,
		&.glider-next {
			height: 40px;
			margin-top: -20px;
			width: 40px;

			&::before {
				height: 15px;
				margin-top: -8px;
				width: 15px;
			}
		}
	}
}

#relatedVideosSection {
	margin-top: 15px;

	.related-videos--landingPage {
		.video-thumbnail img {
			border-radius: 5px;
		}
	}

	.glider-buttons-inline {
		display: flex;
		top: -40px;

		&:has(~ .glider-loading) {
			display: none;
		}
	}
}

#pdRelatedVideos {
	margin: 0 -10px;

	.related-videos__item {
		padding: 4px;

		&:focus {
			border-radius: 2px;
			outline: var(--fieldHighlightWidth) solid var(--fieldHighlightColor);
			outline-offset: 0;

			.relatedVideoAsMyml & {
				outline-offset: -1px;
			}
		}
	}
}

#pdModalRelatedVideos {
	margin: 0 45px;

	.related-videos__item:focus {
		outline: var(--fieldHighlightWidth) solid var(--fieldHighlightColor);
		outline-offset: 0;
	}
}

#player {
	margin: auto;
}

.lpModalRelatedVideos #lpModalContent {
	overflow: hidden !important;
}
