.tradePartners { 
	border: 1px solid #e0e0e0;
	margin: 0 auto 55px auto;
	max-width: 1280px;

	> h2 {
		background-color: #fff;
		display: block;
		margin: -13px auto 0;
		position: relative;
		text-align: center;
		width: 250px;
	}

	.tradePartnerItem {
		flex-basis: 100%;
		margin: 0 24px;
	}
}

.tradePartnerItemsWrapper {
	align-items: center;
	padding: 15px 0 20px;
	text-align: center;
}
