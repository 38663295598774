@import '../global/style-guide';

$black: #000;
$white: #fff;
$lightGray: #f7f7f7;
$lightGrayBlue: #ccd0d9;
$maxWidth: 1280px;

#bdContent {
	@media (min-width: 980px) {
		overflow-x: hidden;
	}
}

#lpContainer {
	@include responsiveWidthLayout;
}

.professionalsLanding {
	&__title {
		font-size: 1.8em;
		font-weight: 400;
		letter-spacing: -0.04em;
		margin-bottom: 25px;
		text-align: center;
	}

	.header {
		display: block;
		font-size: 1.8em;
		font-weight: 400;
	}

	.flex {
		display: flex;
		justify-content: center;
	}

	.tradePartners {
		border: 0;
		border-top: 2px solid #e2e2e2;
		border-bottom: 2px solid #e2e2e2;
		padding: 40px 0 20px;
		margin-bottom: 35px;

		.tradePartnerItem {
			margin: 0 10px;
		}

		& > h2 {
			font-size: 1.8em;
			font-weight: 400;
			letter-spacing: -0.04em;
			margin: auto;
			text-align: center;
		}
	}

	#relatedVideosSection {
		margin-top: 0;
		padding-bottom: 40px;

		.pdSectionTitle {
			color: #000;
			cursor: default;
			font: normal 1.8em "Gotham", Arial, Helvetica, sans-serif;
			font-weight: 400;
			letter-spacing: -0.04em;
			margin-bottom: 15px;
			text-align: center;
			text-transform: capitalize;
			;
		}

		.playButton {
			align-items: center;
			align-self: center;
			border-radius: 50%;
			color: #fff;
			display: flex;
			font-size: 25px;
			height: 75px;
			justify-content: center;
			left: calc((50%) - (32px));
			position: absolute;
			width: 64px;
			z-index: 10;
		}

		.related-videos__item {
			padding-top: 1px;
		}
	}
}

.professionalsSplash {
	background: url('/img/landing-pages/professionals/Pros-landing-image.jpg') top center no-repeat;
	background-size: contain;
	font-family: var(--fontHeading);
	margin: 0 auto 15px;
	max-width: 1600px;
	padding-bottom: calc(37% - 100px);
	position: relative;

	.professionalInfo {
		background: #ffffff;
		display: flex;
		flex-flow: column;
		justify-content: center;
		left: 50%;
		min-height: 98px;
		padding: 20px 30px 30px;
		position: absolute;
		text-align: center;
		top: 62%;
		transform: translate(-50%, -50%);
		width: 425px;

		.memberProSignIn {
			margin-top: 8px;

			span,
			a {
				color: #000000;
			}

			a:hover {
				color: var(--red);
			}
		}
	}

	.logo {
		height: auto;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;
		width: 350px;
	}

	.slogan {
		color: $black;
		font-size: 1.5em;
		font-weight: normal;
		line-height: 1.3em;
		margin-bottom: 12px;
	}
}

.proSignIn .createAccount {
	background: $black;
	display: block;
	font-size: 20px;
	line-height: 40px;
	margin: 0 auto;
	max-width: 300px;

	&:visited {
		background: $black;
	}

	&.disable {
		pointer-events: none;
		background: #555;
	}
}

.professionalsSplash_contactBanner {
	color: $black;
	font-family: var(--fontHeading);
	font-size: 1.4em;
	margin: 20px 0 40px;
	text-align: center;

	@media screen and (max-width: 1200px) {
		font-size: 1.3em;
	}

	#chatLink {
		display: inline-block;
		min-width: unset;
		margin-top: 0;
		height: auto;

		.chatWrapper {
			width: 49px;

			.chatButtonContainer {
				height: 19px;

				button {
					background: transparent;
					border: none;
					color: #111;
					font-family: var(--fontHeading);
					font-size: 19.6px;
					height: 19px;
					left: 0;
					position: relative;
					top: 50%;
					right: unset;
					padding: 0px;
					transform: translateY(-50%);
					text-transform: inherit;
					text-decoration: underline;
					width: 100%;
				}
			}
		}
	}
}

.membershipBenefits {
	border-top: 2px solid #e2e2e2;
	border-bottom: 2px solid #e2e2e2;
	margin: 40px auto;
	max-width: $maxWidth;
	padding: 60px 20px;
	text-align: center;

	.benefitItem {
		margin: 0 20px 0;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&.membershipBorder {
		border: 0;
		border-top: none;
		margin: 0 auto;
		padding: 0 60px 20px;
	}
}

.CPSARelease {
	background: #f1f1f1;
	font-family: var(--fontHeading);
	font-size: 18px;
	max-width: 1280px;
	margin: 0 auto 50px;
	padding: 15px 0;
	text-align: center;

	a {
		text-decoration: underline;
		white-space: nowrap;
	}
}

.membershipBenefits .benefitHeading {
	font-size: 1.3em;
	font-weight: 500;
	margin-bottom: 5px;
}

.membershipBenefits .benefitItem .benefitDescription {
	font-family: var(--fontHeading);
	line-height: 1.6;
}

.topBrands {
	margin: 30px auto 0;
	max-width: $maxWidth;
	padding: 40px 0 20px;

	h2 {
		font-size: 1.8em;
		font-weight: 400;
		letter-spacing: -0.04em;
		margin: 0 0 20px;
		text-align: center;
	}

	&--wrapper {
		border-bottom: 2px solid #e2e2e2;
		margin-bottom: 50px;
	}
}

.topBrandsItemsWrapper {
	padding: 15px 0 20px;
	text-align: center;
}

.topBrandsItem {
	flex-basis: 100%;
	margin: 0 24px;
}

.clientTestimonials {
	margin: 0 auto;
	max-width: 1220px;
	text-align: center;
}

.clientTestimonials .testimonialItem {
	flex-basis: 100%;
	margin: 0 20px;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.testimonialPortrait {
	height: 94px;
	margin: 0 auto 15px;
	width: 94px;
}

img.testimonialPortraitImg {
	border: 1px solid #dedede;
	border-radius: 50%;
	height: 100%;
	width: 100%;
}

.testimonialQuote {
	font-family: var(--fontHeading);
	font-size: 1.3rem;
	font-weight: 500;
}

.testimonialSource {
	font-family: var(--fontHeading);
	font-size: 1.3rem;
	font-style: italic;
	font-weight: 500;
}

.getFreeAccessBanner {
	font-family: var(--fontHeading);
	font-weight: 500;
	margin: 0 auto;
	max-width: $maxWidth;
	padding: 25px 0;

	&__info {
		background-color: #f1f1f1;
		padding: 2%;
		text-align: center;
		width: 100%;
	}

	h2 {
		font-size: 1.8em;
		font-weight: 400;
		letter-spacing: -0.04em;
		line-height: 1.1;
		margin: 0 0 20px;
		text-align: center;
	}

	p {
		font-weight: 400;
		margin: 0 0 30px;
	}

	.createAccount {
		background: $black;
		display: block;
		font-size: 1.1em;
		margin: auto;
		max-width: 200px;
		padding: 12px 0;

		&:visited {
			background: $black;
		}
	}
}

.proLandingVideo {
	$videoTransparentWhite: rgba(244, 244, 244, 0.85);
	cursor: pointer;
	overflow: hidden;
	position: relative;
	z-index: 1;

	&::after {
		background-color: rgba(0, 0, 0, 0.3);
		content: '';
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: opacity 200ms ease;
		width: 100%;
		z-index: 5;
	}

	&:hover::after {
		opacity: 1;
	}

	.playButton {
		align-items: center;
		align-self: center;
		border-radius: 50%;
		color: #fff;
		display: flex;
		font-size: 65px;
		height: 65px;
		justify-content: center;
		left: calc((50%) - (33px));
		position: absolute;
		top: calc((50%) - (40px));
		width: 65px;
		z-index: 10;
	}

	img {
		display: block;
	}

	&__overlay {
		bottom: 0;
		color: $white;
		font-size: 2em;
		font-weight: 500;
		left: 0;
		line-height: 1;
		padding: 0 20px 30px;
		position: absolute;
		text-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
		text-transform: uppercase;
	}
}

.instagramFeed--4Across {
	margin: 35px auto 5px;
	max-width: 1280px;

	#pixleeProsWidget {
		box-sizing: border-box;
		margin: 0 auto;
		max-width: 1230px;
		padding: 0 3%;
		position: relative;

		@media (max-width: 1279px) {
			width: 960px;

			@media (orientation: portrait) {
				width: 945px;
				padding: 0 2%;
				margin: 0 5px;
			}
		}
	}

	h2 {
		font-size: 40px;
		font-weight: 400;
		letter-spacing: -0.04em;
		margin-bottom: 5px;
		text-align: center;
		text-transform: capitalize;
	}

	.subHead {
		display: block;
		font-family: "Gotham SSm A", "Gotham SSm B", Arial, Helvetica, sans-serif;
		font-size: 22px;
		font-weight: 400;
		margin-bottom: 5px;
		text-align: center;
		text-transform: none;
	}

	.HomeWidget--Pros {
		max-width: 1279px;

		@media (max-width: 1279px) {
			height: 665px;
			width: 900px;
		}
	}
}

.aboutLPPros {
	font-family: var(--fontHeading);

	@media screen and (max-width: 1200px) {
		padding: 0 10px;
	}

	p {
		margin: 0.25em 0 0;
	}
}

.lpModalRelatedVideos {
	#lpModalContent {
		padding: 0;
	}
}

.LPProsDisclaimers {
	font-family: var(--fontHeading) p {
		margin: 0.5em;
	}
}

// Professional Layout Changes
.professional_two_way_shop {
	margin: auto;
	max-width: 1150px;

	@media screen and (max-width:1200px) {
		padding: 0 20px;
	}

	.proRow {
		display: flex;
		flex-wrap: wrap;
		font-family: var(--fontHeading);

		.proColumn-6 {
			background-color: #f1f1f1;
			display: flex;
			flex: 1;
			margin: 0 7px;
			padding: 2rem 4rem 1rem;

			span {
				display: inline-block;
				margin-right: 20px;

				@media screen and (max-width: 1200px) {
					img {
						max-width: 96px;
					}
				}
			}

			h3 {
				font-weight: 500;
				margin: 5px 0;
			}

			p {
				color: #000;
				font-size: 14px;
				font-weight: 400;
				line-height: 1.2;
				margin: 0;

				@media screen and (max-width: 1200px) {
					br {
						display: none;
					}
				}
			}
		}
	}

	h2 {
		font-size: 1.8em;
		font-weight: 400;
		letter-spacing: -0.04em;
		margin-bottom: 20px;
		text-align: center;
	}

}

#lpContainer {
	max-width: 1600px;
	width: calc(100%);

	.professionalsLandingContainer {
		max-width: 1440px;
		margin: auto;
		width: calc(100% - 60px);
	}
}

.welcomeUser {
	font-size: 20px;
	font-weight: normal;
}